import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faTwitter }  from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

import './styles/social.styl'

const SocialBar = (props) => (
    <div className='socialbar'>
        {/* should this be div or span, should use id or class? */}
        { props.fb && <SocialLink link={props.fb} icon={ faFacebookF }/> }
        { props.tw && <SocialLink link={props.tw} icon={ faTwitter }/> }
        { props.email && <SocialLink link={props.email} icon={ faEnvelope }/> }
    </div>
)

export default SocialBar

const SocialLink = (props) => (
    <a href={props.link} target='_blank' rel='noopener noreferrer'>
        <FontAwesomeIcon icon={props.icon}/>
    </a>
)
