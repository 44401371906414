import React from 'react'
import PropTypes from 'prop-types'

import Logo from './images/logo.svg'

import './styles/header.styl'

const Header = ({ siteTitle }) => (
  <header>
    <h1>{siteTitle}</h1>
    <object id='logo' type='image/svg+xml' data={Logo} height='200' width='200' aria-label='Snake Bacon Logo'/>
    <h2>Game Studios</h2>
    <svg id='angle' viewBox='0 0 100 100' preserveAspectRatio='none'>
      <polygon fill='white' points='0,100 0,0 50,98 100,0 100,100'/>
    </svg>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: '',
}

export default Header
