import React from 'react'

import SocialBar from './social'

import './styles/footer.styl'

const fblink = 'https://www.facebook.com/snakebacongames/'
const twlink = 'https://twitter.com/snakebacongames'
const emailaddr = 'mailto:contact@snakebacon.com'

const Footer = () => (
    <footer>
        <SocialBar fb={fblink} tw={twlink} email={emailaddr}/>
        <p>
            Stay connected with us <br/>
            Based in Austin, TX
        </p>
        <hr id='line'/>
        <p><small id='copyright'>Copyright &copy; 2018 Snake Bacon LLC</small></p>
    </footer>
)

export default Footer